import * as React from 'react';
import styled from 'styled-components';
import { GameDetails } from "../components/game";

const RingOfFireRow = styled.p`
  background-color: white;
  padding-left: 15px;
  padding: 10px 20px;
  border-radius: 5px;
`;

const RingOfFireCardRank = styled.strong`
  font-size: 1.5em;
`;

const RingOfFireCardRule = styled.div`
`;

export const animals: GameDetails = {
  name: 'Animals',
  category: 'Word',
  imageName: 'Octopus',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/octopus_501197',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'Animals',
  howToPlay: (
    <div>
      <p>
        Each player chooses an animal to be during the game, and decides on both a noise and an action that they'll do for that animal.
        For example, a player might choose to be a parrot whose noise is a squawk and whose action is flapping your arms. Players should try
        to pick funny noises and actions to make the game interesting.
      </p>
      <p>
        The game begins after everyone has decided on their animal and told the other players what it is. The first player starts by making their
        own noise and action, followed by the noise and action of another player.
        Play then passes to that player, who must do the noise and action of the first player, their own noise and action, then the noise
        and action of any other player (including the first player). Play then passes to that player, and so on.
      </p>
      <p>
        The first person to make a mistake or hesitate for too long must down their drink. If you're finding the game too easy you can introduce an extra
        rule - anyone who laughs must take a drink.
      </p>
    </div>
  ),
  metaDescription: `A quick and easy game for three or more players that involves making ridiculous animal noises. Great for when you don't have a pack of cards to hand.`,
};

export const battleshots: GameDetails = {
  name: 'Battleshots',
  category: 'Other',
  imageName: 'Ship',
  imageAuthor: 'Freepik',
  imageUrl: 'https://www.flaticon.com/free-icon/ship_419008',
  playersRequired: '2',
  playersRecommended: '2',
  pageName: 'Battleshots',
  setup: (
    <div>
      <p>
        You'll need to draw or print out two Battleships game boards, with squares large enough to fit shot glasses on. You'll also need enough shot glasses to form
        the following ships for each player:
      </p>
      <p><strong>Destroyer</strong> - two shot glasses</p>
      <p><strong>Submarine</strong> - three shot glasses</p>
      <p><strong>Cruiser</strong> - three shot glasses</p>
      <p><strong>Battleship</strong> - four shot glasses</p>
      <p><strong>Aircraft carrier</strong> - five shot glasses</p>
    </div>
  ),
  howToPlay: (
    <div>
      <p>
        Each player places their 'ships' (made up of a number of shot glasses) on their game board and fills the shot glasses with the drink(s) of their choice.
        It's important to ensure that each player's game board cannot be seen by their opponent (propped up pizza boxes are good for this).
      </p>
      <p>
        The players take it in turns to guess a coordinate on their opponent's game board. If the coordinate they guess has a shot glass on it their opponent must
        drink its contents and remove it from the board.
      </p>
      <p>
        The winner is the first player to destroy all of their opponents ships (i.e. make their opponent drink all of
        their shots).
      </p>
    </div>
  ),
  metaDescription: `A boozy spin on the childhood classic, with the ships replaced by shot glasses. This is a great head-to-head game for two players.`,
};

export const beerPong: GameDetails = {
  name: 'Beer Pong',
  category: 'Other',
  imageName: 'Cup',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/take-away_452927',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'BeerPong',
  setup: (
    <div>
      <p>
        You'll need a long table, twenty large plastic cups, and some ping pong balls.
      </p>
      <p>
        Place ten cups at either end of the table, each arranged into a
        4-3-2-1 pyramid. The pyramids should be pointing towards the center of the table.
      </p>
      <p>
        Fill each of the cups with beer (or the drink(s) of your choice). For smaller groups you may want to make the pyramids out of six cups rather than ten.
      </p>
    </div>
  ),
  howToPlay: (
    <div>
      <p>
        Divide the players equally into two teams. The teams stand at opposite ends of the table and take it in turns to have one player make a throw.
      </p>
      <p>
        When making a throw the aim is to land the ping pong ball in one of the other team's cups. The throw must be made from behind the edge of the table (no
        leaning forward) and the ball can bounce at most once. If the ball lands inside an opponent's cup then one member of that team must down the contents
        of the cup, before removing the cup from the table.
      </p>
      <p>
        One team wins when all their opponent's cups have been removed from the table.
        At this point the losing team is allowed one final throw before they have to drink the contents of all the winning team's remaining cups.
      </p>
      <p>
        Cups cannot be rearranged during the game, except for at certain specific times. When a team has six cups remaining their opponent can choose to have them
        rearranged into a 3-2-1 pyramid (if they aren't already in this pattern). Alternatively, when a team has four cups remaining their opponent can choose
        to have them rearranged into a 1-2-1 diamond (if they aren't already in this pattern). A team can only request a 're-rack' once during the game,
        either when the opposing team has six cups remaining, or when they have four cups remaining.
      </p>
    </div>
  ),
  metaDescription: `A classic drinking game for two teams where players try to throw a ping-pong ball into one of the opposing team's cups. Popular at University socials.`,
};

export const boatRace: GameDetails = {
  name: 'Boat Race',
  category: 'Other',
  imageName: 'Rowboat',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/rowboat_184049',
  playersRequired: '4+',
  playersRecommended: '6+',
  pageName: 'BoatRace',
  howToPlay: (
    <div>
      <p>
        Divide the players evenly into two teams (this game doesn't work as well when there are an odd number of players). Each players grabs a drink,
        then the two teams line up side by side.
      </p>
      <p>
        After a countdown the first player in line on each team starts downing their drink. As soon as the
        first player finishes their drink, the player behind them in line starts downing their drink. When they finish their drink, the player behind
        them starts downing their drink, and so on.
      </p>
      <p>
        The winning team is the team that finishes their drinks first. It's important to remember that a
        player may only start downing their drink when the player ahead of them in line has finished drinking.
      </p>
      <p>
        If a player starts drinking early their
        team is disqualified.
      </p>
    </div>
  ),
  metaDescription: `An awesome team drinking game where the winning team is the one that downs their drinks the fastest. Popular at University socials.`,
};

export const cardHead: GameDetails = {
  name: 'Card Head',
  category: 'Card',
  imageName: 'Cards',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/cards_147206',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'CardHead',
  setup: (
    <p>
      You'll need a deck of cards.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        A card is dealt face down to every player. Each player takes their card and, without looking at it, sticks it to their forehead (or holds it there
        if it doesn't stick). Players may then bet on whether their own card is higher or lower than another player's card.
      </p>
      <p>
        If a player loses a bet they must
        drink the amount they wagered, otherwise they may nominate another player to drink.
      </p>
    </div>
  ),
  metaDescription: `A great drinking game for the gamblers among us - each player sticks a playing card to their head and bets drinks on whether it's higher than their opponent's.`,
};

export const centurion: GameDetails = {
  name: 'Centurion',
  category: 'Other',
  imageName: 'Helmet',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/helmet_468755',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'Centurion',
  setup: (
    <p>
      You'll need a few shot glasses for each player.
    </p>
  ),
  howToPlay: (
    <p>
      The aim of this game is to drink a shot of beer (or a drink of your choice) every minute for one-hundred minutes. Anybody who completes this challenge
      earns the title of 'Centurion'.
    </p>
  ),
  metaDescription: `Players have 100 minutes to drink 100 shots of beer. Take the challenge and earn the title of Centurion with this classic endurance drinking game.`,
};

export const chaseTheAce: GameDetails = {
  name: 'Chase the Ace',
  category: 'Card',
  imageName: 'Ace of Spades',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/ace-of-spades_359385',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'ChaseTheAce',
  setup: (
    <p>
      You'll need a deck of cards.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        In this game the dealer passes from one player to the next after each round.
        The aim of the game is to avoid having the lowest card at the end of the round.
      </p>
      <p>
        To start with the dealer shuffles the deck and deals one card face down to each player, including themselves.
        The player to the left of the dealer looks at their card and decides whether they want to 'swap' or 'stick'.
        If they choose to 'swap' they can swap their card with the player on their left, otherwise they 'stick'.
        Any player who has a King can turn it face up on the table, and the player to their right is not able to swap with them.
      </p>
      <p>
        Play continues around to the left until it reaches the dealer.
        Once play reaches the dealer everyone must turn their card face up, and the dealer then decides what to do.
        The dealer can choose to 'stick', but unlike the other players the dealer cannot 'swap'.
        Instead they may cut the deck to try to get a higher card.
      </p>
      <p>
        The person who has the lowest card after the dealer has acted must drink.
        Aces are considered to be low, which is where the name of the game comes from.
      </p>
      <p>
        At the end of the round the dealer role passes one to the left and a new round begins.
      </p>
    </div>
  ),
  metaDescription: `A quick and easy drinking game twist on the classic card game - at the end of each round the player with the lowest card has to drink.`,
};

export const cheat: GameDetails = {
  name: 'Cheat',
  category: 'Card',
  imageName: 'Suspicious',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/suspicious_260196',
  playersRequired: '3+',
  playersRecommended: '4+',
  pageName: 'Cheat',
  setup: (
    <p>
      You'll need a deck of cards.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        To begin, deal the deck of cards out evenly between all players.
        Players take it in turns to play between one and four cards of the same value face down in the middle.
      </p>
      <p>
        Whenever a player places cards down they must announce how many cards they're putting down, and what value the cards have (for example 'two fives').
        The twist is that players can only put down cards which have a value equal to or next to the value of the previously placed cards
        (for example if the first player places down two fives, the next player must place down fours, fives, or sixes).
      </p>
      <p>
        Players are not required to play fairly, and may instead place down cards which differ from what they announce.
        A player may call 'Cheat!' if they suspect that another player has placed down different cards to what they've announced.
        If the player was indeed cheating they must drink a number of fingers equal to the value of the card they were claiming to have placed down, otherwise the accuser must drink that many fingers instead.
      </p>
      <p>
        The winner is the first player to get rid of all their cards.
      </p>
    </div>
  ),
  metaDescription: `The most dishonest drinking game of them all - you can't win without cheating, but don't get caught or you'll have to drink.`,
};

export const crossTheBridge: GameDetails = {
  name: 'Cross the Bridge',
  category: 'Card',
  imageName: 'Bridge',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/bridge_385942',
  playersRequired: '2+',
  playersRecommended: '3+',
  pageName: 'CrossTheBridge',
  setup: (
    <p>
      You'll need a deck of cards.
      Shuffle the deck and deal ten cards face down in a line to form 'the bridge'.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        Players take it in turns to flip over a card, starting at one end of the bridge and moving from one card to the next.
        If a player turns over a numbered card they're safe and play passes to the next player.
        If a player turns over a face card they must drink and then add a number of cards face down to the far end of the bridge.
        The penalty depends on the card they turn over:
      </p>
      <p><strong>Jack</strong> - one finger, one card</p>
      <p><strong>Queen</strong> - two fingers, two cards</p>
      <p><strong>King</strong> - three fingers, three cards</p>
      <p><strong>Ace</strong> - four fingers, four cards</p>
      <p>
        The game is over when either the players have successfully made it from one end of the bridge to the other by turning over all the cards, or when cards cannot be added to the bridge because the deck has run out.
      </p>
    </div>
  ),
  metaDescription: `Ten cards are dealt face-down in a line - players must cross from one side to the other without turning over a face card or they'll have to drink.`,
};

export const drinkingSnap: GameDetails = {
  name: 'Drinking Snap',
  category: 'Card',
  imageName: 'Poker',
  imageAuthor: 'Madebyoliver',
  imageUrl: 'http://www.flaticon.com/free-icon/poker_138531',
  playersRequired: '3+',
  playersRecommended: '4+',
  pageName: 'DrinkingSnap',
  setup: (
    <p>
      You'll need a deck of cards.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        Choose a player to be the dealer for the first round.
        After each round the role of dealer passes to the next player.
        A round consists of the dealer placing cards face up in a pile, one after another, calling out the number of each card as they do so.
      </p>
      <p>
        When two cards of the same value come up one after another the first person to place their hand on the pile and shout 'Snap!' can nominate another player to drink.
      </p>
      <p>
        A round ends when the dealer has no more cards.
      </p>
    </div>
  ),
  metaDescription: `A drinking game version of the classic card game which is likely to get messy. Try to win early else the more drunk you get the slower you'll react.`,
};

export const firingLine: GameDetails = {
  name: 'Firing Line',
  category: 'Card',
  imageName: 'Target',
  imageAuthor: 'Nikita Golubev',
  imageUrl: 'http://www.flaticon.com/free-icon/target_364578',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'FiringLine',
  setup: (
    <div>
      <p>
        You'll need a deck of cards and some shot glasses (no more than thirteen).
      </p>
      <p>
        First place the shot glasses in a line and fill each one with a drink of your choice.
      </p>
      <p>
        Next separate all the spades from the deck and shuffle them.
        Deal one spade face up in front of the first shot glass, then one spade face up in front of the second shot glass, and so on.
        If you've dealt one spade in front of every shot glass but still have some left, simply start again at the first shot glass and deal out a second spade, but make sure that the first card is still visible.
      </p>
      <p>
        Repeat this process until you've dealt out all of the spades.
        Note that some shot glasses may end up with one fewer card in front of them than the others.
      </p>
    </div>
  ),
  howToPlay: (
    <div>
      <p>
        After performing the setup described above, take the remaining cards in the deck and shuffle them.
      </p>
      <p>
        Players take it in turns to draw a card from the deck and do the shot that matches the card they've drawn.
        For example, a player who draws the four of hearts must drink the shot that has the four of spades in front of it.
        Once the player has had their shot they remove the matching spade from in front of the shot glass and discard it.
        If there is still another spade in front of the shot glass then the shot glass gets refilled, otherwise it is left empty.
      </p>
      <p>
        If a player draws a card whose matching spade has been discarded then they don't have to drink.
      </p>
      <p>
        The game ends when all of the spades have been discarded, which means all the shots have been drunk.
      </p>
    </div>
  ),
  metaDescription: `A tense drinking game where a row of shots are lined up and players take turns drawing a card. Get unlucky and you'll have to drink a shot.`,
};

export const fives: GameDetails = {
  name: 'Fives',
  category: 'Word',
  imageName: 'Hand',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/hand_169013',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'Fives',
  howToPlay: (
    <div>
      <p>
        At the start of the game every player is 'in'. Each round of the game starts with the player whose turn it is doing a countdown of 'three, two, one' followed by a multiple of five.
        For example, 'three, two, one, twenty'.
        At the same time as the player says the multiple of five every player holds out a hand, with either a closed fist or an open hand.
      </p>
      <p>
        If the number of outstretched fingers is equal to the multiple of five that the player said then that player is 'out'.
        They must not celebrate, but must instead say the phrase 'thank you for a lovely game of fives' with a completely straight face.
        If they celebrate or fail to say this phrase then they are still 'in'.
      </p>
      <p>
        If the number of outstretched fingers is not equal to the multiple of five that the player said, then that player is still 'in' and must drink.
      </p>
      <p>
        The aim of the game is to get 'out' by correctly guessing the number of fingers that will be held outstretched.
        The last player to be left 'in' must down their drink.
      </p>
    </div>
  ),
  metaDescription: `A popular drinking game at University socials - everyone shows an open hand or closed fist, and players must guess the total number of outstretched fingers.`,
};

export const fuckTheDealer: GameDetails = {
  name: 'Fuck the Dealer',
  category: 'Card',
  imageName: 'Croupier',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/croupier_122448',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'FuckTheDealer',
  setup: (
    <p>
      You'll need a deck of cards.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        To begin with the players must decide who will start out as the dealer, and whether aces are high or low.
      </p>
      <p>
        The dealer holds the deck of cards face down and asks the player on their left to guess the top card.
        If the player guesses correctly the dealer must drink, otherwise the dealer tells them whether the top card is higher or lower than the card they guessed at.
        The player then guesses once more.
        If this second guess is correct then the dealer must drink, otherwise the player drinks a number of fingers equal to the difference between their guess and the actual card.
      </p>
      <p>
        The dealer then repeats this whole process with the next player, and so on.
        If the dealer gets through three players in a row without having to drink then they pass the deck to the player on their left, who is now the dealer.
      </p>
      <p>
        Cards which have been revealed are placed face up on the table so that players know which cards are no longer in the deck, making it harder for the dealer as the game goes on.
      </p>
    </div>
  ),
  metaDescription: `Players take it in turns to be the dealer in a type of Higher or Lower game - but as the game goes on it gets easier and easier to 'Fuck the Dealer'.`,
};

export const fuzzyDuck: GameDetails = {
  name: 'Fuzzy Duck',
  category: 'Word',
  imageName: 'Duckling',
  imageAuthor: 'Madebyoliver',
  imageUrl: 'http://www.flaticon.com/free-icon/duckling_135077',
  playersRequired: '3+',
  playersRecommended: '4+',
  pageName: 'FuzzyDuck',
  howToPlay: (
    <div>
      <p>
        The first player starts the game by saying 'fuzzy duck'. The player to their left then says 'fuzzy duck', and play continues round in this fashion.
        However, at any point a player may instead choose to say 'duzzy?', in which case the direction of play is reversed and the phrase changes to 'ducky fuzz'.
      </p>
      <p>
        Every time a player says 'duzzy?' the direction changes and the phrase flips from 'fuzzy duck' to 'ducky fuzz' (or vice-versa).
        Each player may only say 'duzzy?' up to two times in a single round.
      </p>
      <p>
        The idea of the game is to go round quickly without giving players too much time to think.
      </p>
      <p>
        If a player makes a mistake or hesitates for too long they must drink, and the game starts again.
      </p>
    </div>
  ),
  metaDescription: `A popular game at University socials - players take it in turns to say 'Fuzzy Duck' or 'Ducky Fuzz' depending on the situation. If you mess up you drink.`,
};

export const higherOrLower: GameDetails = {
  name: 'Higher or Lower',
  category: 'Card',
  imageName: 'Exchange',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/exchange_143215',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'HigherOrLower',
  setup: (
    <p>
      You'll need a deck of cards.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        Choose someone to be the dealer.
        The dealer starts by dealing a card face up to the player on their left.
        That player must then guess whether the next card will be higher or lower than the card they've been dealt.
      </p>
      <p>
        If the player guesses incorrectly they must drink a number of fingers equal to the difference between the two cards.
      </p>
      <p>
        If the player guesses correctly, or if the second card has the same rank as the first, then they play again.
      </p>
      <p>
        If a player survives three rounds they nominate another player to drink, and also get to make a new rule.
        Play passes to the next player if a player guesses incorrectly, or if they manage to survive three rounds.
      </p>
    </div>
  ),
  metaDescription: `A good quick and easy drinking game - guess whether the next card will be higher or lower than the one you've been dealt. Get it wrong and you drink.`,
};

export const horseRacing: GameDetails = {
  name: 'Horse Racing',
  category: 'Card',
  imageName: 'Jockey',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/jockey_406158',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'HorseRacing',
  setup: (
    <div>
      <p>
        You'll need a deck of cards.
      </p>
      <p>
        Separate the aces from the deck, then shuffle the remaining cards and deal between five and ten face down in a line to form the 'race track'.
      </p>
      <p>
        Finally, place the aces face up in a row in line with the first card of the race track.
      </p>
    </div>
  ),
  howToPlay: (
    <div>
      <p>
        At the beginning of the game each player places a bet on a particular ace.
        For example, a player might bet three fingers on the ace of spades.
        Once all bets have been placed the race begins.
      </p>
      <p>
        A designated player draws cards one by one from the top of the deck and turns them face up on the table.
        Each time a card is turned over the ace with the matching suit is advanced one place along the race track.
        For example, if the four of diamonds is turned over then the ace of diamonds is moved forward one place (i.e. in line with the next card on the race track).
      </p>
      <p>
        A race ends when one of the aces wins by reaching the last position on the race track (i.e. is in line with the last card on the race track).
        At this point each player settles their bet, drinking the amount they wagered if they lose or nominating other players to drink if they win.
      </p>
    </div>
  ),
  metaDescription: `A great drinking game for large groups - the four Aces race along a line of face-down cards, with players betting on which suit will win.`,
};

export const invisibleBall: GameDetails = {
  name: 'Invisible Ball',
  category: 'Word',
  imageName: 'Ball',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/ball_484487',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'InvisibleBall',
  howToPlay: (
    <div>
      <p>
        In this game players pass around an invisible ball using three words: 'whizz', 'bounce', and 'boing'.
      </p>
      <p><strong>Whizz</strong> - the ball passes to the next player</p>
      <p><strong>Bounce</strong> - the ball skips over the next player and lands at the following player</p>
      <p><strong>Boing</strong> - the ball changes direction</p>
      <p>
        The first player starts the game by specifying an initial direction of play (e.g. 'to my left') and saying one of the three words.
        Whichever player has the ball says one of the words to move it along.
      </p>
      <p>
        If a player makes a mistake or hesitates for too long they must drink, and the game starts again.
      </p>
    </div>
  ),
  metaDescription: `A popular drinking game at University socials - players say 'Whizz', 'Bounce' or 'Boing' to throw an invisible ball. Don't drop the ball or you have to drink.`,
};

export const neverHaveIEver: GameDetails = {
  name: 'Never Have I Ever',
  category: 'Word',
  imageName: 'Prohibited',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/prohibited_390956',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'NeverHaveIEver',
  howToPlay: (
    <div>
      <p>
        Players take it in turns to name something they've never done. For example, 'never have I ever had a threesome'.
        Any player who has done this thing must drink, and if they are the only player to do so they must down their drink.
        If nobody drinks then the player whose turn it is must drink instead.
      </p>
      <p>
        A player can take advantage of how well they know the other players by choosing specific things that they know certain people have done.
      </p>
    </div>
  ),
  metaDescription: `A popular game with students (especially freshers) - players take it in turns to say something they've never done, and anyone who has done it must drink.`,
};

export const pyramid: GameDetails = {
  name: 'Pyramid',
  category: 'Card',
  imageName: 'Pyramid',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/pyramid_292159',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'Pyramid',
  setup: (
    <p>
      You'll need a deck of cards. Shuffle the deck and deal out fifteen cards face down in a 5-4-3-2-1 pyramid.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        Players take it in turns to climb the pyramid by turning a card over from each row of the pyramid, starting at the bottom.
        If the player turns over a face card during this process they must drink a penalty depending on the card they turned over.
      </p>
      <p><strong>Jack</strong> - one finger</p>
      <p><strong>Queen</strong> - two fingers</p>
      <p><strong>King</strong> - three fingers</p>
      <p><strong>Ace</strong> - four fingers</p>
      <p>
        Once the player has drunk their penalty they must deal new cards face down to replace the cards they turned over during their attempt, before trying to climb the pyramid again.
      </p>
      <p>
        Play passes on when the player successfully climbs the pyramid by turning over a card from each of the five rows without revealing a face card.
      </p>
    </div>
  ),
  metaDescription: `A pyramid of face-down cards is laid out - players must get from the bottom of the pyramid to the top without turning over a face card or they have to drink.`,
};

export const rideTheBus: GameDetails = {
  name: 'Ride the Bus',
  category: 'Card',
  imageName: 'Bus',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/school-bus_183756',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'RideTheBus',
  setup: (
    <div>
      <p>
        You'll need a deck of cards.
        Shuffle the deck and deal out sixteen cards face down in a 1-2-3-4-3-2-1 diamond.
      </p>
      <p>
        For an easier game you can deal out a smaller diamond of cards.
      </p>
    </div>
  ),
  howToPlay: (
    <div>
      <p>
        This game is very similar to both <a href='/DrinkingGames/CrossTheBridge'>Cross the Bridge</a> and <a href='/DrinkingGames/Pyramid'>Pyramid</a>.
        Players take it in turns to 'ride the bus' by turning a card over from each row of the diamond, starting at one end and moving across to the other.
      </p>
      <p>
        If the player turns over a face card during this process they must drink a number of fingers equal to the number of cards they turned over during that attempt.
        For example, if a player reaches the middle row and turns over a face card they must drink four fingers, since there will be four cards face up.
      </p>
      <p>
        Once the player has drunk their penalty they must deal new cards face down to replace the cards they turned over during their attempt, before riding the bus again.
      </p>
      <p>
        Play passes on when the player successfully completes their bus ride (i.e. turns over a card from each of the seven rows without revealing a face card).
      </p>
    </div>
  ),
  metaDescription: `A diamond of face-down cards is laid out - players must 'ride the bus' from one side to the other without turning over a face card or they have to drink.`,
};

export const ringOfFire: GameDetails = {
  name: 'Ring of Fire',
  category: 'Card',
  imageName: 'Ring of Fire',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/ring-of-fire_257424',
  playersRequired: '3+',
  playersRecommended: '6+',
  pageName: 'RingOfFire',
  setup: (
    <p>
      You'll need a deck of cards and a pint glass.
      Put the pint glass in the middle of the table and spread the deck of cards face down around it.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        Players take it in turns to draw a card and perform the corresponding action. When all the cards are gone the game ends.
        Any player who 'breaks the circle' by taking a card such that the ring of cards is disconnected must down their drink.
        There are many different rulesets you can play with, one of which is given below.
      </p>
      <div>
        <RingOfFireRow>
          <RingOfFireCardRank>2</RingOfFireCardRank>
          <RingOfFireCardRule>'You' - the person who draws this card may designate another player to drink.</RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>3</RingOfFireCardRank>
          <RingOfFireCardRule>'Me' - the person who draws this card must drink.</RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>4</RingOfFireCardRank>
          <RingOfFireCardRule>'Whores' - all female players must drink.</RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>5</RingOfFireCardRank>
          <RingOfFireCardRule>
            'Thumb master' - the person who draws this card may at any point during the game place their thumb on the table, and the last person to follow suit must drink
            (the next player to draw a 5 takes over the role of thumb master).
          </RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>6</RingOfFireCardRank>
          <RingOfFireCardRule>'Dicks' - all male players must drink.</RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>7</RingOfFireCardRank>
          <RingOfFireCardRule>
            'Heaven' - the person who draws this card may at any point during the game point upwards towards the heavens, and the last person to follow suit must drink
            (the next player to draw a 7 takes over this power).
          </RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>8</RingOfFireCardRank>
          <RingOfFireCardRule>'Mate' - the person who draws this card nominates another player to be their 'mate', and every time they drink their 'mate' must also drink.</RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>9</RingOfFireCardRank>
          <RingOfFireCardRule>
            'Rhyme' - the person who draws this card chooses a word, the players then go round in a circle naming words which rhyme with the original word and the
            first player to hesistate for more than a few seconds or repeat a word that has already been said must drink.
          </RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>10</RingOfFireCardRank>
          <RingOfFireCardRule>
            'Categories' - the person who draws this card chooses a category and names something which belongs in that category, the players then go round in a circle
            naming things that fit the category and the first player to hesitate for more than a few seconds or repeat something that has already been said must drink.
          </RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>Jack</RingOfFireCardRank>
          <RingOfFireCardRule>
            'Snake eyes' - the person who draws this card gains 'snake eyes' and any player who makes eye contact with them must drink
            (the next player to draw a Jack takes over this power).
          </RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>Queen</RingOfFireCardRank>
          <RingOfFireCardRule>
            'Question master' - the person who draws this card becomes the 'question master' and any player who answers a question posed by them must drink
            (the next player to draw a queen takes over the role of question master).
          </RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>King</RingOfFireCardRank>
          <RingOfFireCardRule>
            'King's cup' - the person who draws this card must add some of their drink the the pint glass in the middle of the table, and can also create a new rule which
            stands for the duration of the game. The person who draws the fourth and final King must down the contents of the pint glass in the middle of the table.
          </RingOfFireCardRule>
        </RingOfFireRow>
        <RingOfFireRow>
          <RingOfFireCardRank>Ace</RingOfFireCardRank>
          <RingOfFireCardRule>
            'Waterfall' - everybody starts drinking, and cannot stop drinking until the person to their right has stopped drinking (starting with the person who drew the card).
          </RingOfFireCardRule>
        </RingOfFireRow>
      </div>
    </div>
  ),
  metaDescription: `Every student's favourite drinking game - each card has a different rule and the unlucky person who draws the fourth King has to see away the dirty pint.`,
};

export const sentence: GameDetails = {
  name: 'Sentence',
  category: 'Word',
  imageName: 'Chat',
  imageAuthor: 'Alfredo Hernandez',
  imageUrl: 'http://www.flaticon.com/free-icon/chat_458964',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'Sentence',
  howToPlay: (
    <p>
      The first player says a word, then the player to their left says another word which could follow the first word in a sentence, and so on.
      The game continues round as a sentence is built up word by word, until someone hesitates for too long or says a word that doesn't make sense.
      This player must drink, and the game starts again.
    </p>
  ),
  metaDescription: `A funny drinking game with no setup required - players make up a sentence between them by taking it in turns to say a word. If you mess up you have to drink.`,
};

export const slapClapClick: GameDetails = {
  name: 'Slap, Clap, Click',
  category: 'Word',
  imageName: 'Clap',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/clap_119956',
  playersRequired: '3+',
  playersRecommended: '4+',
  pageName: 'SlapClapClick',
  howToPlay: (
    <div>
      <p>
        The first player picks a category, such as 'animals' or 'makes of car'.
      </p>
      <p>
        Once the category has been decided all the players must get into a steady rhythm of 'slap, clap, click, click'.
        The 'slap' noise is made by slapping your thighs with both hands at the same time,
        the 'clap' noise is made by clapping your hands together, and
        the 'click' noise is made by clicking your fingers on both hands at the same time.
      </p>
      <p>
        Once everyone is doing the 'slap, clap, click, click' rhythm in time the game can begin.
        Players take it in turns to name something which belongs to the category, but they must say it on the fourth beat of the rhythm (i.e. the second 'click').
      </p>
      <p>
        If play reaches a player but they fail to say something on the fourth beat then they must drink.
        If a player repeats something that has already been said or names something that doesn't fit the category they must down their drink.
        In addition to this, any player who loses rhythm with the rest of the players must drink.
      </p>
    </div>
  ),
  metaDescription: `Players bash out a drum beat while taking it in turns to name something from a specified category. If you lose rhythm or hesitate too long you have to drink.`,
};

export const theAlphabetGame: GameDetails = {
  name: 'The Alphabet Game',
  category: 'Word',
  imageName: 'Cubes',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/cubes_191557',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'TheAlphabetGame',
  howToPlay: (
    <p>
      The first player begins by choosing a category (such as 'animals') and naming something within that category that starts with the letter 'A'.
      The player to their left then names something within the category that starts with the letter 'B'.
      Play continues round in this fashion until someone makes a mistake or hesitates for too long.
      This player must drink, and the game starts again.
    </p>
  ),
  metaDescription: `A category is chosen and players take it in turns to name something from that category which starts with the next letter of the alphabet.`,
};

export const theCelebrityGame: GameDetails = {
  name: 'The Celebrity Game',
  category: 'Word',
  imageName: 'Dressing Room',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/dressing-room_432831',
  playersRequired: '3+',
  playersRecommended: '4+',
  pageName: 'TheCelebrityGame',
  howToPlay: (
    <div>
      <p>
        The first player starts by naming a celebrity.
        The player to their left must then name a different celebrity whose name begins with the first letter of that celebrity's surname.
        For example, 'Matt Damon' followed by 'Daniel Radcliffe'.
      </p>
      <p>
        Play then moves to the next player, who must name a celebrity whose name begins with the first letter of the previous celebrity's surname
        (in the above example this would be 'Daniel Radcliffe', so they would need to name a celebrity whose name begins with 'R', such as 'Rick Astley').
      </p>
      <p>
        Play continues around like this, but if at any point someone names a celebrity whose first name and surname start with the same letter then the direction of play is reversed (for example 'Sylvester Stallone').
      </p>
      <p>
        If a player makes a mistake or hesitates for too long they must drink and the game resets.
        If a player repeats a celebrity that has already been said they must down their drink.
      </p>
    </div>
  ),
  metaDescription: `A quick and easy drinking game - players take it in turns to name a celebrity whose first name starts with the same letter as the previous celebrity's surname.`,
};

export const theDealerAndTheFuzz: GameDetails = {
  name: 'The Dealer and The Fuzz',
  category: 'Card',
  imageName: 'Police',
  imageAuthor: 'Eucalyp',
  imageUrl: 'http://www.flaticon.com/free-icon/policeman_427213',
  playersRequired: '4+',
  playersRecommended: '5+',
  pageName: 'TheDealerAndTheFuzz',
  setup: (
    <p>
      You'll need a deck of cards.
      Take a number of cards from the deck equal to the number of players, ensuring that there is exactly one King and exactly one Ace among them.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        Shuffle the cards you've pulled from the deck and deal one card face down to each player.
        Each player looks at their own card without showing anyone else.
        The player that has the King is 'the Fuzz', and the player that has the Ace is 'the Dealer'.
      </p>
      <p>
        The Dealer must discreetly wink at another player, who will then wait a few seconds before announcing that 'the deal has been made'.
        At this point the Fuzz turns over their card to identify themself, then tries to guess who the Dealer is.
      </p>
      <p>
        For each incorrect guess the Fuzz must drink, and when they finally guess correctly the Dealer must drink a number of fingers equal to the number of players who have not been accused by the Fuzz.
        At this point the cards are shuffled and dealt out again, and the game is repeated as before.
      </p>
    </div>
  ),
  metaDescription: `In this drinking game one player is The Dealer, who must discreetly wink at another player without being caught by The Fuzz.`,
};

export const theDictator: GameDetails =   {
  name: 'The Dictator',
  category: 'Card',
  imageName: 'Crown',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/skull_194614',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'TheDictator',
  setup: (
    <p>
      You'll need a deck of cards.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        The game is played in rounds until the players decide to stop.
        A round begins with a card being dealt to each player.
      </p>
      <p>
        The player who has the highest card is the dictator (players cut the deck in the event of a draw).
        Once the dictator has been determined everyone hands back in their card.
      </p>
      <p>
        The dictator then decides on a set of rules which apply to the cards in the deck (for example a black cards means take a drink, a King means down your drink etc.).
        A new card is dealt to every player and everyone must act according to the dictator's rules, except that whenever a rule applies to the dictator they may instead apply it to another player.
      </p>
      <p>
        After everyone has performed the necessary actions the round ends and a new dictator is determined.
      </p>
    </div>
  ),
  metaDescription: `In each round one lucky player becomes The Dictator - this grants them the power to make rules which decide who drinks and who doesn't.`,
};

export const theForeheadGame: GameDetails = {
  name: 'The Forehead Game',
  category: 'Word',
  imageName: 'Symbols',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/unknown-user-symbol_37943',
  playersRequired: '2+',
  playersRecommended: '4+',
  pageName: 'TheForeheadGame',
  setup: (
    <p>
      You'll need some post-it notes (or some paper and sticky tape) and a pen.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        Each player writes down the name of a famous person on a post-it note (or piece of paper) and sticks it on the forehead of the player to their left, so that the player themself cannot see the name but everyone else can.
      </p>
      <p>
        Players take it in turns to ask 'yes' or 'no' questions to the rest of the group in order to figure out who they are.
        For example, 'am I a man?' or 'am I in the entertainment industry?'.
        If the answer to their question is 'yes' they get to ask another question, otherwise they must drink and play passes to the next player.
      </p>
      <p>
        The last player to guess who they are must down their drink.
      </p>
    </div>
  ),
  metaDescription: `Each player has the name of a person stuck to their forehead and must guess who they are by asking yes or no questions, drinking every time the answer is 'no'.`,
};

export const theWotLeWot: GameDetails = {
  name: 'The Wot-le-wot',
  category: 'Word',
  imageName: 'Donate',
  imageAuthor: 'Becris',
  imageUrl: 'http://www.flaticon.com/free-icon/donation_489116',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'TheWotlewot',
  howToPlay: (
    <div>
      <p>
          Firstly you need to find a small object that can be passed easily between players.
          This can be absolutely anything, such as an empty beer bottle. This item is 'the Wot-le-wot'.
      </p>
      <p>
          The first player (let's call them Player A) takes the Wot-le-wot, presents it to another player (player B), and says 'this is the Wot-le-wot'.
          Player B must then respond with 'the what?', to which Player A replies 'the Wot-le-wot'.
          At this point Player B says 'ah the Wot-le-wot', and takes the object from them.
      </p>
      <p>
          Player B then presents the Wot-le-wot to another player (Player C) and says 'this is the Wot-le-wot'.
          Player C responds with 'the what?', and Player B must then ask Player A 'the what?', to which Player A responds 'the Wot-le-wot'.
          Player B then turns to player C and says 'the Wot-le-wot', at which point Player C says 'ah the Wot-le-wot' and takes the object.
      </p>
      <p>
          The game continues in this fashion, with players asking 'the what?' all the way down the chain back to the original player, and then saying 'the Wot-le-wot' all the way back up the chain to the new player.
      </p>
      <p>
          Players are free to present the Wot-le-wot to the player who last gave them it, or to players who have already been given the Wot-le-wot.
          If a player makes a mistake or hesitates for too long they must drink, and the game starts again.
      </p>
    </div>
  ),
  metaDescription: `Players pass a random object between themselves whilst trying to remember the order in which it went round. Anyone who messes up has to drink.`,
};

export const tvTaches: GameDetails = {
  name: 'TV Taches',
  category: 'Other',
  imageName: 'Moustache',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/moustache_424442',
  playersRequired: '2+',
  playersRecommended: '2+',
  pageName: 'TvTaches',
  setup: (
    <p>
      You'll need a TV, a pen, some paper, some scissors, and some blu-tack or sticky tape.
    </p>
  ),
  howToPlay: (
    <div>
      <p>
        This is a fun, casual drinking game that you can play while watching a film or some TV.
      </p>
      <p>
        Draw out some moustaches on a piece of paper, then cut them out and stick them in random places on your TV screen.
        Whenever a face on screen lines up with a moustache, everyone must drink.
      </p>
      <p>
        To make this game a little more competetive, have each player draw out a moustache and place it on the screen.
        Whenever a moustache lines up with a face on screen, the player that drew the moustache can nominate someone else to drink.
      </p>
    </div>
  ),
  metaDescription: `A chilled out drinking game to play when watching TV - each player sticks a paper moustache to the TV and drinks when it lines up with someone's face.`,
};

export const twentyOne: GameDetails = {
  name: 'Twenty-One',
  category: 'Word',
  imageName: '21',
  imageAuthor: 'pre-drinks.com',
  imageUrl: 'http://www.pre-drinks.com/',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'TwentyOne',
  howToPlay: (
    <div>
      <p>
        The aim of this game is to avoid saying 'twenty-one'.
      </p>
      <p>
        To begin the first player proposes a game, specifies the initial direction of play, and starts by saying 'one'.
        For example, 'I propose a game of twenty plus one, to my right, one' (the phrase 'twenty plus one' is used to avoid saying 'twenty-one').
      </p>
      <p>
        In this example the player to their right would then say 'two', the player to the right of them would say 'three', and so on until a player has to say 'twenty-one'.
        However, instead of just counting up by a single number a player may choose to count up by two or three numbers.
      </p>
      <p>
          If a player counts up by two numbers (e.g. 'three, four') they switch the direction of play.
          If a player counts up by three numbers (e.g. 'three, four, five') then play skips the next player.
          In addition to this, it is forbidden to 'double a double' (count up two numbers when the player before you also counted up two numbers) or 'triple a triple' (count up three numbers when the player before you also counted up three numbers).
      </p>
      <p>
          If at any point a player makes a mistake or hesitates for too long they must drink, and the game starts back at 'one'.
          If the counting reaches twenty-one the player who says it must down their drink and make a new rule.
      </p>
      <p>
          Rules should be chosen to make the game more entertaining or more difficult, for example 'instead of saying the number ten you have to remove an item of clothing' or 'whenever you say a multiple of three you have to do it in a foreign accent'.
      </p>
    </div>
  ),
  metaDescription: `Popular at University socials - players count up to 21 by saying between one and three numbers at a time, and whoever gets to 21 has to down their drink.`,
};

export const whatTheFuck: GameDetails = {
  name: 'What The Fuck',
  category: 'Word',
  imageName: 'Questions',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/question_263205',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'WhatTheFuck',
  howToPlay: (
    <div>
      <p>
        Before the game begins each player must choose a name for themselves which takes the form of a descriptive word followed by the word 'fuck'.
        Some examples are 'quick fuck', 'sad fuck', 'sneaky fuck', etc.
      </p>
      <p>
        Once everybody has chosen a name and told everyone else what it is the game can begin.
        The games starts by all the players chanting 'what's the name of the fucking game say what, the, fuck'.
        The first player then chants their own name, followed by the phrase 'what the fuck', and finally the name of another player.
      </p>
      <p>
        For example, 'sad fuck, what the fuck, how about a sneaky fuck?'.
        Play then passes to that player, who does the same thing with their own name and the name of another player.
      </p>
      <p>
        If a player makes a mistake or hesitates for too long they must drink, and the game starts again.
      </p>
    </div>
  ),
  metaDescription: `Popular at University socials - everyone chooses a name for themself and a chant is started. Play passes to someone when the active player calls out their name.`,
};

export const wouldYouRather: GameDetails = {
  name: 'Would You Rather',
  category: 'Word',
  imageName: 'Arrows',
  imageAuthor: 'Freepik',
  imageUrl: 'http://www.flaticon.com/free-icon/transfer_191457',
  playersRequired: '3+',
  playersRecommended: '5+',
  pageName: 'WouldYouRather',
  howToPlay: (
    <div>
      <p>
        Players take it in turns to pose a 'would you rather' question to the group.
        For example, 'would you rather have teeth for eyelashes or eyelashes for teeth?'.
      </p>
      <p>
        The player who posed the question then counts down 'three, two, one, show'.
        On the word 'show' everyone puts a hand forward in either a closed fist (first option) or an open hand (second option) to indicate which option they're voting for.
      </p>
      <p>
        Every player who voted for the less popular option must drink, and everyone drinks in the case of a draw.
      </p>
    </div>
  ),
  metaDescription: `A great game to play at Freshers - players take turns to pose a 'Would you rather?' question and everyone who votes for the less popular option has to drink.`,
};

export const allGames: Array<GameDetails> = [
  animals,
  battleshots,
  beerPong,
  boatRace,
  cardHead,
  centurion,
  chaseTheAce,
  cheat,
  crossTheBridge,
  drinkingSnap,
  firingLine,
  fives,
  fuckTheDealer,
  fuzzyDuck,
  higherOrLower,
  horseRacing,
  invisibleBall,
  neverHaveIEver,
  pyramid,
  rideTheBus,
  ringOfFire,
  sentence,
  slapClapClick,
  theAlphabetGame,
  theCelebrityGame,
  theDealerAndTheFuzz,
  theDictator,
  theForeheadGame,
  theWotLeWot,
  tvTaches,
  twentyOne,
  whatTheFuck,
  wouldYouRather,
];
